import React from 'react';
import './Services.css';
import './App.css';
function Services({ setCurrentPage }) {
  return (
    <div>
        <div className="main-services">
        <div className="services-parent">
            <div className="services-navbar">
                <button className="secondary-btn" onClick={() => setCurrentPage('home')}>Back</button>
                <div className="services-title">Our Services</div>
                <button className="primary-btn" onClick={() => window.location.href = 'https://cal.com/athca/30min?user=athca&month=2024-08&layout=month_view&date=2024-08-09'}>Book a call</button>
            </div>
            <div className="services-container">
                <div className="explainer-section">
                    <div className="explainer-container">
                        <div className="t2">We really love a challenge</div>
                        <div className="p1-explainer">We eat complex user flows (i.e. wallet apps, marketplaces, exchanges) for breakfast.</div>
                    </div>
                    <div className="explainer-container">
                        <div className="t2-2">Buttttt...</div>
                        <div className="p1-explainer-2">We specialise in web apps and do not cover 3D modelling, animated graphics, document design, complex packaging and print design.</div>
                    </div>
                </div>
                <div className="services-section">
                    <div className="service-square">
                        <img src="/assets/star.png" className="service-icon" alt="Superb Quality"></img>
                        <div className="t3">Superb Quality</div>
                        <div className="p2">It’s all about the feel. Your customers should know you’re the real deal before even reading the about page.</div>
                    </div>
                    <div className="service-square">
                        <img src="/assets/heart.png" className="service-icon" alt="Internalised Design"></img>
                        <div className="t3">Internalised Design</div>
                        <div className="p2">Think of us as your right-hand man. We deep dive into your business to offer a deeply connected design experience.</div>
                    </div>
                    <div className="service-square">
                        <img src="/assets/bolt.png" className="service-icon" alt="Fast Delivery"></img>
                        <div className="t3">Fast Delivery</div>
                        <div className="p2">They say speed doesn’t equate to quality. We beg to differ.</div>
                    </div>
                    <div className="service-square">
                        <img src="/assets/handshake.png" className="service-icon" alt="Reasonable Pricing"></img>
                        <div className="t3">Reasonable Pricing</div>
                        <div className="p2">We offer seriously competitive pricing. We want to grow alongside you and that means being flexible with pricing.</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default Services;
