import React, { useState } from 'react';
import './App.css';
import Home from './Home';
import Services from './Services';

function App() {
  

  const [currentPage, setCurrentPage] = useState('home');

  const renderPage = () => {
    if (currentPage === 'home') {
      return <Home setCurrentPage={setCurrentPage} />;
    } else if (currentPage === 'services') {
      return <Services setCurrentPage={setCurrentPage} />;
    }
  };

  return (
    <div className="App">
      <div className="navbar">
        <img src="/assets/nlm-logo-nobackground.png" className="nlmlogo" alt="logo"></img>
        <div className="NLM">NLM</div>
        <div className="Studio">Studio</div>
      </div>
      
      {renderPage()}

      {currentPage !== 'services' && (
        <div className="works">
          <img src="/assets/portfolio-14.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-13.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-1.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-2.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-3.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-4.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-5.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-6.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-7.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-8.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-9.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-10.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-11.png" className="works-item" alt="works-item"></img>
          <img src="/assets/portfolio-12.png" className="works-item" alt="works-item"></img>
        </div>
      )}
    </div>
  );
}

export default App;
